import PropTypes from "prop-types";

const SessionDetails = ({ data }) => {
	return (
		<div className="pr-2">
			{Object.keys(data).map((key, index) => {
				return (
					<div className="flex items-center mb-6 justify-between" key={index}>
						<div className="flex items-center w-full justify-between mx-auto space-x-2">
							<p className="text-color-secondary text-sm w-1/2 break-all place-self-start">{key}</p>
							<p className="text-color-primary text-sm w-1/2 break-all place-self-end">{data[key]}</p>
						</div>
					</div>
				);
			})}
		</div>
	);
};

SessionDetails.propTypes = {
	data: PropTypes.object.isRequired
};

SessionDetails.defaultProps = {
	data: {}
};

export default SessionDetails;
