import { relativeTime, titleCase } from "../../../common/prCommon";
import { SESSION_STATUS_TEXT } from "../../../constants";

// images
import DurationSvg from "../../../assets/images/duration.svg";
import DeviceSvg from "../../../assets/images/device.svg";
import IdSvg from "../../../assets/images/id.svg";
import LocalSiteTestingSvg from "../../../assets/images/local-site-testing.svg";
import LastUpdatedSvg from "../../../assets/images/last-updated.svg";
import OsVersionSvg from "../../../assets/images/os-version.svg";
import StatusSvg from "../../../assets/images/status.svg";
import UserSvg from "../../../assets/images/user.svg";

const SessionInfo = ({ session, sessionDuration, sessionUser }) => {
	let infoContent = [
		{
			icon: StatusSvg,
			iconAltText: "Session status",
			heading: "Status",
			content: SESSION_STATUS_TEXT[session.status] || "-"
		},
		{
			icon: DurationSvg,
			iconAltText: "Session duration",
			heading: "Duration",
			content: sessionDuration
		},
		{
			icon: DeviceSvg,
			iconAltText: "Device",
			heading: "Device",
			content: session.device
		},
		{
			icon: UserSvg,
			iconAltText: "User Name",
			heading: "User",
			content: sessionUser
		},
		{
			icon: IdSvg,
			iconAltText: "Session ID",
			heading: "Session ID",
			content: session.id
		},
		{
			icon: LastUpdatedSvg,
			iconAltText: "Last updated at",
			heading: "Last Updated",
			content: relativeTime(session.updatedAt)
		},
		{
			icon: OsVersionSvg,
			iconAltText: "OS",
			heading: "OS",
			content: titleCase(session.os)
		},
		{
			icon: LocalSiteTestingSvg,
			iconAltText: "Local site testing",
			heading: "Local Site Testing",
			content: session.pCloudy_WildNet ? "True" : "False"
		}
	];
	return (
		<div className="h-auto grid grid-cols-1 md:grid-cols-4 space-y-6 py-8 justify-items-center md:justify-items-start p-4  ">
			{infoContent.map((info, index) => {
				return (
					<div className="flex items-center space-x-4 px-2 break-all" key={index}>
						<img className={`h-12 ${index === 0 ? "align-middle" : ""}`} src={info.icon} alt={info.iconAltText} />
						<div>
							<span className="block text-color-tertiary text-xs">{info.heading}</span>
							<span className="block text-color-primary text-base font-weight-normal">{info.content}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default SessionInfo;
