import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";

const CompareModal = ({ isOpen, onRequestClose, data }) => {
	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		const configureChartData = async () => {
			const buildComparisonDataPromise = data.map((build) => {
				const total = (build.completed || 0) + (build.inprogress || 0) + (build.passed || 0) + (build.failed || 0);
				return [
					build.name.length >= 12 ? `${build.name.substring(0, 9)}...` : build.name,
					total > 0 ? ((build.completed || 0) / total) * 100 : 0,
					total > 0 ? ((build.inprogress || 0) / total) * 100 : 0,
					total > 0 ? ((build.passed || 0) / total) * 100 : 0,
					total > 0 ? ((build.failed || 0) / total) * 100 : 0
				];
			});

			const buildComparisonData = await Promise.all(buildComparisonDataPromise);
			setChartData([["Builds", "Completed (%)", "In Progress (%)", "Passed (%)", "Failed (%)"], ...buildComparisonData]);
		};

		if (Array.isArray(data) && data.length !== 0) {
			configureChartData();
		}
	}, [data]);

	return (
		<div>
			<div className={`fixed z-10 inset-0 overflow-y-auto ${isOpen ? "block" : "hidden"}`} onClick={onRequestClose}>
				<div className="flex items-center justify-center min-h-screen px-4 py-8">
					<div className="fixed inset-0 transition-opacity" aria-hidden="true">
						<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
					</div>

					<div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-4xl sm:w-full p-8 relative" onClick={(e) => e.stopPropagation()}>
						<button className="absolute top-6 right-6 text-2xl text-gray-600 hover:text-gray-800" onClick={onRequestClose}>
							&times;
						</button>
						<h2 className="text-black mb-4 text-xl font-bold">Build Comparison</h2>
						{chartData.length > 1 ? (
							<Chart
								chartType="Bar"
								width="100%"
								height="400px"
								data={chartData}
								options={{
									chart: {
										title: "",
										subtitle: "Comparing various metrics across selected builds (in %)"
									},
									bars: "vertical",
									hAxis: { title: "Builds" },
									vAxis: { title: "Percentage (%)" },
									colors: ["#1C7DF4", "#E0A455", "#00BD85", "#E85252"],
									legend: { position: "right" },
									tooltip: { isHtml: true }
								}}
							/>
						) : (
							<p className="text-black">No build data available for comparison</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompareModal;
