import React, { useEffect, useState, useContext, useRef } from "react";

// components
import { SubHeader } from "./components/sub-header";
import { SharedSubHeader } from "./components/shared-sub-header";
import { Card } from "../../components/card";
import PageNotFound from "../notFound";
import Table from "./components/table";
import PageMeta from "../../components/page-meta";
import BuildInfo from "./components/build-info";
import { DeleteModal } from "../../components/delete-modal";
import buildController from "./controllers/build-controller";

// contexts
import { LoaderContext } from "../../contexts/loader-context";

// utility
import { localTime, titleCase, secondsToTimeInUnits, relativeTime } from "../../common/prCommon";
import { SESSION_STATUS_HEX, SESSION_STATUS_TEXT, SESSION_FILTER, BUILD_STATUS } from "../../constants";
import BuildFilter from "./components/build-filter";
import Pagination from "./components/pagination";
import TestResultChart from "./components/session-chart";

const BuildPage = ({ match, isShared = false }) => {
	const [buildDetails, setBuildDetails] = useState({});
	const [buildFound, setBuildFound] = useState(true);
	const [buildUsername, setBuildUsername] = useState("-");
	const [sessionsResp, setSessionsResp] = useState([]);
	const [loadingSessionDetailsTable, setLoadingSessionDetailsTable] = useState(false);
	const [totalSessionsCount, setTotalSessionsCount] = useState("-");
	const { setLoading } = useContext(LoaderContext);
	const buildId = match.params.id;

	const [serverEvent, setServerEvent] = useState({});
	const [listening, setListening] = useState(false);

	const params = Object.fromEntries(new URLSearchParams(window.location.search));
	const activeFiltersInitialState = {
		[SESSION_FILTER.STATUS]: {},
		[SESSION_FILTER.OS]: {},
		[SESSION_FILTER.DEVICES]: {},
		[SESSION_FILTER.TAGS]: {}
	};
	const shareToken = isShared ? (params.hasOwnProperty("sharetoken") ? params.sharetoken : "") : "";

	//set previously applied filters stored in query params if any to active filters
	const [activeFilters, setActiveFilters] = useState(params.hasOwnProperty("filters") ? JSON.parse(params.filters) : activeFiltersInitialState);
	const [isDelete, setDelete] = useState(false);
	const [pieChartData, setPieChartData] = useState([]);
	const [buildDuration, setBuildDuration] = useState("-");
	const intervalRef = useRef();
	const lastUpdated = useRef("");

	useEffect(() => {
		buildController.getBuildById(buildId, isShared, shareToken).then((resp) => {
			if (resp.status === 200) {
				setBuildDetails(resp.data);
				setLoading(false);
			} else {
				setBuildFound(false);
				setLoading(false);
			}
		});

		getAndSetAllSessions(getQueryParamsOfActiveFilters(), false);

		buildController.getTotalSessionsById(buildId, isShared, shareToken).then((resp) => {
			setTotalSessionsCount(resp?.data?.total);
		});

		buildController
			.getStatusCount(buildId, isShared, shareToken)
			.then((resp) => {
				let data = resp.data.map((obj) => {
					return [titleCase(obj.status), parseInt(obj.count)];
				});
				data = [["status", "count"]].concat(data);
				setPieChartData(data);
			})
			.catch((error) => {
				console.log(error);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverEvent]);

	useEffect(() => {
		if (!listening && !isShared) {
			const buildId = match.params.id;
			let events = buildController.subscribeBuild(buildId);
			events.onmessage = (event) => {
				if (event.data !== "keep-alive") setServerEvent(event);
			};
			return () => {
				events.close();
			};
		}

		setListening(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listening]);

	useEffect(() => {
		if (buildDetails.updatedAt) lastUpdated.current = relativeTime(buildDetails.updatedAt);
		buildController.getBuildUsername(buildId, isShared, shareToken).then((resp) => {
			setBuildUsername(resp.data.username);
		});
		buildController.getBuildDuration(buildId, isShared, shareToken).then((resp) => {
			let duration = resp.data.timeTaken;
			let oldestInprogressStartTime = resp.data?.oldestInprogressStartTime;
			if (duration <= 0 && oldestInprogressStartTime === undefined) {
				setBuildDuration("-");
				return;
			}
			if (buildDetails.status)
				if (buildDetails.status === BUILD_STATUS.INPROGRESS) {
					if (oldestInprogressStartTime !== undefined) {
						duration += (new Date() - new Date(oldestInprogressStartTime)) / 1000;
					}
					setBuildDuration(secondsToTimeInUnits(duration));
					clearInterval(intervalRef.current);
					let id = setInterval(() => {
						duration++;
						setBuildDuration(secondsToTimeInUnits(duration));
					}, 1000);
					intervalRef.current = id;
				} else {
					setBuildDuration(secondsToTimeInUnits(duration));
					clearInterval(intervalRef.current);
				}
			return () => clearInterval(intervalRef.current);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buildDetails]);

	const getAndSetAllSessions = async (queryParams, showloading = true) => {
		if (showloading) {
			setLoadingSessionDetailsTable(true);
		}
		let resp = await buildController.getBuildSessionsById(buildId, isShared, shareToken, queryParams);
		if (resp.status === 200) {
			setSessionsResp(resp?.data);
			setLoadingSessionDetailsTable(false);
			setLoading(false);
		} else {
			setLoading(false);
			setLoadingSessionDetailsTable(false);
		}
	};

	const getQueryParamsOfActiveFilters = () => {
		let str = [];
		for (let filter in activeFilters) {
			if (Object.keys(activeFilters[filter]).length === 0) continue;
			str.push(`${filter}=${Object.keys(activeFilters[filter]).join(",").toLowerCase()}`);
		}
		return str.join("&");
	};

	const columns = React.useMemo(
		() => [
			{
				Header: "Test case name",
				accessor: "name",
				cellWidth: "20%",
				sortDescFirst: "true"
			},
			{
				Header: "Device",
				accessor: "device",
				cellWidth: "20%",
				disableSortBy: true
			},
			{
				Header: "Last updated",
				accessor: "updatedAt",
				cellWidth: "20%",
				Cell: ({ cell: { value } }) => {
					return value ? localTime(value) : "-";
				}
			},
			{
				Header: "Automation type",
				disableSortBy: true,
				cellWidth: "20%",
				Cell: (props) => "App testing"
			},
			{
				Header: "Status",
				disableSortBy: true,
				cellWidth: "20%",
				accessor: "status",
				Cell: ({ cell: { value, row } }) => {
					let statusHex = SESSION_STATUS_HEX[row.original.status] || "#000";
					let statusText = SESSION_STATUS_TEXT[row.original.status] || "-";
					return (
						<span style={{ color: statusHex }} className="font-weight-normal">
							{statusText}
						</span>
					);
				}
			}
		],
		[]
	);

	return !buildFound ? (
		<PageNotFound />
	) : (
		<>
			<DeleteModal isDelete={isDelete} setDelete={setDelete} deleteMethod={buildController.deleteBuild} name={buildDetails.name} id={buildId} type={"build"} />
			<PageMeta title="Build Dashboard" description="Build details" />
			<div className="bg-background px-6 py-6 mt-8">
				{isShared === false ? (
					<SubHeader buildName={buildDetails.name} buildId={buildId} backgroundClass={"bg-background"} boxShadow={false} rounded={false} setDelete={setDelete} />
				) : (
					<SharedSubHeader backgroundClass={"bg-background"} boxShadow={false} rounded={false} isShared={isShared} shareToken={shareToken} />
				)}
				<div className="flex flex-row space-x-4 buidInfoAndPieChartContainer">
					<div className="w-2/3">
						<Card>
							<BuildInfo
								buildLastUpdated={lastUpdated.current}
								buildUsername={buildUsername}
								buildDetails={buildDetails}
								buildDuration={buildDuration}
								totalSessionsCount={totalSessionsCount}
							/>
						</Card>
					</div>
					<div className="w-1/3">
						<Card>
							<TestResultChart pieChartData={pieChartData} />
						</Card>
					</div>
				</div>
				<div className="flex flex-col md:flex-row py-4 relative ">
					<div className="w-full">
						<Card>
							<div className="flex space-x-4 pt-6 pb-0">
								<h3 className="font-weight-normal text-sm self-center">Session Details</h3>

								<div className="py-4 flex space-x-2 relative">
									<BuildFilter
										setSessionsResp={setSessionsResp}
										setLoadingSessionDetailsTable={setLoadingSessionDetailsTable}
										buildId={buildId}
										getQueryParamsOfActiveFilters={getQueryParamsOfActiveFilters}
										activeFilters={activeFilters}
										setActiveFilters={setActiveFilters}
										activeFiltersInitialState={activeFiltersInitialState}
										isShared={isShared}
										shareToken={shareToken}
									/>
								</div>
							</div>
							<div className={` ${loadingSessionDetailsTable ? "opacity-30 pointer-events-none" : ""}  `}>
								<div>
									{!sessionsResp[0]?.sessions?.length ? (
										<div className="text-sm text-center h-96 max-h-650px">No data found.</div>
									) : (
										<Table columns={columns} data={sessionsResp[0]?.sessions} isShared={isShared} shareToken={shareToken} />
									)}
								</div>
							</div>
							<Pagination totalSessionsCount={sessionsResp[0]?.count} getQueryParamsOfActiveFilters={getQueryParamsOfActiveFilters} getAndSetAllSessions={getAndSetAllSessions} />
						</Card>
					</div>
				</div>
			</div>
		</>
	);
};

export default BuildPage;
