import React, { useEffect, useState, useContext, useRef } from "react";

// components
import { SubHeader } from "./components/sub-header";
import { Tabs } from "../../components/tabs";
import { Card } from "../../components/card";
import NoDataFound from "../../components/no-data-found";
import Logs from "./components/logs";
import Appprofile from "./components/appprofile";
import sessionController from "./controllers/session-controller";
import SessionDetails from "./components/session-details";
import PageMeta from "../../components/page-meta";
import SessionVideo from "./components/video";
import PageNotFound from "../notFound";
import SessionInfo from "./components/session-info";
import { DeleteModal } from "../../components/delete-modal";
// contexts
import { LoaderContext } from "../../contexts/loader-context";
// utils
import { SESSION_STATUS } from "../../constants";
import TextLogs from "./components/text-logs";

const SessionPage = ({ match, isShared = false }) => {
	const [session, setSession] = useState({});
	const [textLogs, setTextLogs] = useState([]);
	const [endReached, setEndReached] = useState(false);
	const [updateLog, setUpdateLog] = useState({});
	const [updateLogLive, setUpdateLogLive] = useState({});

	const [cpuAppProfile, setCpuAppProfile] = useState([]);
	const [memAppProfile, setMemAppProfile] = useState([]);

	let lastEventOffset = textLogs.length > 0 ? textLogs[textLogs.length - 1]?.createdAt : null;

	const params = Object.fromEntries(new URLSearchParams(window.location.search));
	const shareToken = isShared ? (params.hasOwnProperty("sharetoken") ? params.sharetoken : "") : "";

	const setlogs = () => {
		setUpdateLog({
			data: "update:textlog"
		});
	};

	const scrollRef = useRef(null);

	// useEffect(() => {
	// 	if (!endReached) {
	// 		if (textLogs.length && virtuoso.current) {
	// 			virtuoso.current.scrollToIndex({
	// 				index: textLogs.length - 1,
	// 				align: "end",
	// 				behavior: "instant"
	// 			});
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [textLogs]);

	let tabs = [
		{
			name: "Text Logs",
			content: () => {
				return textLogs ? (
					<div className="text-sm text-color-secondary">
						<TextLogs logs={textLogs} setlogs={setlogs} scrollRef={scrollRef} />
					</div>
				) : (
					<NoDataFound msg="Please wait for the logs to be available" />
				);
			}
		},
		{
			name: "Device Logs",
			content: () => {
				return session.status !== SESSION_STATUS.INPROGRESS && session.deviceLogsUrl !== null ? (
					<Logs sessionId={session.id} logURL={session.deviceLogsUrl} logType="device" />
				) : (
					<NoDataFound msg="Please wait for the logs to be available" />
				);
			}
		},
		{
			name: "Appium Logs",
			content: () => {
				return session.status !== SESSION_STATUS.INPROGRESS && session.appiumLogsUrl !== null ? (
					<Logs sessionId={session.id} logURL={session.appiumLogsUrl} logType="appium" />
				) : (
					<NoDataFound msg="Please wait for the logs to be available" />
				);
			}
		},
		{
			name: "App Profiling",
			content: () => {
				return session.status !== SESSION_STATUS.INPROGRESS && session.cpuLog !== null && session.memLog !== null ? (
					<div>
						<div>
							<Appprofile appProfile={cpuAppProfile?.data} timestamp={cpuAppProfile?.timeStamp} profilingType="CPU" bgColor="rgb(255, 99, 132)" borderColor="rgba(255, 99, 132, 0.2)" />
						</div>
						<div>
							<Appprofile
								appProfile={memAppProfile?.data}
								timestamp={memAppProfile?.timeStamp}
								profilingType="MEMORY"
								bgColor="rgb(54, 162, 235)"
								borderColor="rgba(54, 162, 235, 0.2)"
							/>
						</div>
					</div>
				) : (
					<NoDataFound msg="Please wait for the app profiling to be available" />
				);
			}
		}
	];

	let sessionDetails = [
		{
			name: "App",
			content: () => {
				return <SessionDetails data={session.appDetails} />;
			}
		},
		{
			name: "Input Capabilities",
			content: () => {
				return <SessionDetails data={session.capabilities} />;
			}
		}
	];

	const [sessionFound, setSessionFound] = useState(true);
	const { setLoading } = useContext(LoaderContext);
	const [serverEvent, setServerEvent] = useState({});
	const [listening, setListening] = useState(false);
	const [isDelete, setDelete] = useState(false);
	const [sessionDuration, setSessionDuration] = useState("-");
	const [sessionUser, setSessionUser] = useState("-");
	const sessionId = match.params.id;
	const hasMounted = useRef(false);

	useEffect(() => {
		if (!endReached) {
			const sessionId = match.params.id;
			sessionController.getTextLogs(sessionId, isShared, shareToken, lastEventOffset).then((resp) => {
				if (resp.data.length === 0) {
					setEndReached(true);
				}
				setTextLogs((prev) => prev.concat(resp.data));
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLog]);

	useEffect(() => {
		if (hasMounted.current) {
			const sessionId = match.params.id;
			sessionController.getTextLogs(sessionId, isShared, shareToken, lastEventOffset, 2).then((resp) => {
				setTextLogs((prev) => {
					if (prev.length === 0 || (prev.length && prev[prev.length - 1]?.createdAt !== resp.data[resp.data.length - 1]?.createdAt)) {
						return prev.concat(resp.data);
					}
					return prev;
				});
			});
		} else {
			hasMounted.current = true;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateLogLive]);

	useEffect(() => {
		const sessionId = match.params.id;
		sessionController
			.getSessionById(sessionId, isShared, shareToken)
			.then((resp) => {
				setSessionFound(true);
				setSession(resp.data);
				setLoading(false);
			})
			.catch((error) => {
				setSessionFound(false);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serverEvent]);

	useEffect(() => {
		if (sessionFound) {
			if (session.status !== SESSION_STATUS.INPROGRESS) {
				sessionController.getSessionDuration(sessionId, isShared, shareToken).then((resp) => {
					setSessionDuration(resp.data.duration);
				});
			}
			if (session.cpuLog)
				sessionController.getAppProfileData(session.cpuLog).then((resp) => {
					if (resp) setCpuAppProfile(resp);
				});
			if (session.memLog)
				sessionController.getAppProfileData(session.memLog).then((resp) => {
					if (resp) setMemAppProfile(resp);
				});
			if (session.userId) {
				sessionController.getUsername(sessionId, isShared, shareToken).then((resp) => {
					if (sessionUser === "-" && resp.data && resp.data?.username) setSessionUser(resp.data.username);
				});
			}
		}
	}, [session]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {}, [cpuAppProfile]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {}, [memAppProfile]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!listening && !isShared) {
			const sessionId = match.params.id;
			const events = sessionController.subscribeSession(sessionId);
			events.onmessage = (event) => {
				if (event.data !== "keep-alive") {
					if (event.data === "update") {
						setServerEvent(event);
					}
					if (event.data === "update:textlog") {
						setUpdateLogLive({
							data: "update:textlog"
						});
					}
				}
			};
			return () => {
				events.close();
			};
		}

		setListening(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [listening]);

	return !sessionFound ? (
		<PageNotFound />
	) : (
		<>
			<DeleteModal isDelete={isDelete} setDelete={setDelete} deleteMethod={sessionController.deleteSession} name={session.name} id={session.id} type={"session"} prevId={session?.build?.id} />
			<PageMeta title="Session Dashboard" description="Session details" />
			<div className="bg-background px-6 py-6 mt-8">
				{isShared === false ? (
					<SubHeader
						sessionName={session.name}
						sessionStatus={session.status}
						sessionId={sessionId}
						buildName={session?.build?.name}
						buildId={session?.build?.id}
						backgroundClass={"bg-background"}
						boxShadow={false}
						rounded={false}
						setDelete={setDelete}
					/>
				) : (
					<></>
				)}
				<Card>
					<SessionInfo session={session} sessionUser={sessionUser} sessionDuration={sessionDuration} />
				</Card>
				<div className="sessionMainContainer flex flex-col md:flex-row space-x-3 py-4 relative h-screen max-h-650px">
					<div className="w-full md:w-3/5">
						<Card>
							<Tabs tabs={tabs} />
						</Card>
					</div>
					<div className="md:flex flex-col w-full md:w-2/5 space-y-2 ">
						<div className="h-2/5">
							<SessionVideo session={session} />
						</div>
						<div className="h-3/5">
							<Card>
								<h3 className=" pt-2">Session Details</h3>
								<Tabs tabs={sessionDetails} />
							</Card>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SessionPage;
