import { useContext } from "react";
import Loader from "react-loader-spinner";

// contexts
import { LoaderContext } from "../contexts/loader-context";

const FullPageLoader = () => {
	const { isLoading } = useContext(LoaderContext);
	return !isLoading ? (
		""
	) : (
		<div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-white flex flex-col items-center justify-center">
			<Loader type="ThreeDots" color="#07223e" height={50} width={50} visible="true" timeout={0} />
		</div>
	);
};

export default FullPageLoader;
