import { useEffect, useState } from "react";
import prClient from "../../../common/prClient";
import FilterButton from "../../../components/filter/filter-button";
import ResetFilters from "../../../components/filter/reset-filters";
import SelectedFilterCards from "../../../components/filter/selected-filter-cards";
import ProjectDetailsFilterPopup from "../../../components/filter/filter-popup";
import { BUILD_FILTER, BUILD_FILTER_TEXT, BUILD_STATUS, BUILD_STATUS_TEXT } from "../../../constants";
import projectController from "../controller/project-controller";

const ProjectFilter = ({ allBuilds, setAllBuilds, setLoadingBuildDetailsTable }) => {
	const activeFiltersInitialState = {
		[BUILD_FILTER.STATUS]: {},
		[BUILD_FILTER.PROJECT_NAME]: {},
		[BUILD_FILTER.BUILD_NAME]: {}
	};

	const [isFilterOpen, setFilterOpen] = useState(false);
	const [activeFilters, setActiveFilters] = useState(activeFiltersInitialState);
	const [projectFiltersList, setProjectFiltersList] = useState([]);

	const getQueryParamsOfActiveFilters = () => {
		let str = [];
		for (let filter in activeFilters) {
			if (Object.keys(activeFilters[filter]).length === 0) continue;
			str.push(`${filter}=${Object.keys(activeFilters[filter]).join(",").toLowerCase()}`);
		}
		return str.join("&");
	};

	useEffect(() => {
		getAllProjects();
	}, []);

	const getAllProjects = () => {
		prClient({
			method: "get",
			url: `/project/projectnames`
		}).then(
			(resp) => {
				setProjectFiltersList(resp?.data || []);
			},
			(reason) => {
				console.log(reason);
				//toast showing something went wrong
			}
		);
	};

	const handleFilterChange = () => {
		let queryParams = getQueryParamsOfActiveFilters();
		setLoadingBuildDetailsTable(true);

		projectController.getAllBuilds(queryParams).then((resp) => {
			if (resp.status === 200) {
				setAllBuilds(resp.data);
				setLoadingBuildDetailsTable(false);
			} else {
				setLoadingBuildDetailsTable(false);
			}
		});
	};

	const filters = [
		{
			title: BUILD_FILTER_TEXT[BUILD_FILTER.STATUS],
			key: BUILD_FILTER.STATUS,
			options: Object.fromEntries(
				//options will stored in key: value pair. Here, key will be used in query params and value will be used as text for UI
				Object.values(BUILD_STATUS).map((_, i) => [Object.values(BUILD_STATUS)[i], Object.values(BUILD_STATUS_TEXT)[i]])
			)
		},
		{
			title: BUILD_FILTER_TEXT[BUILD_FILTER.PROJECT_NAME],
			key: BUILD_FILTER.PROJECT_NAME,
			options: Object.fromEntries(Object.values(projectFiltersList).map((_, i) => [Object.values(projectFiltersList)[i].name, Object.values(projectFiltersList)[i].name]))
		}
	];
	return (
		<>
			<FilterButton isFilterOpen={isFilterOpen} setFilterOpen={setFilterOpen} />

			<SelectedFilterCards activeFilters={activeFilters} setActiveFilters={setActiveFilters} FILTERS_TEXT={BUILD_FILTER_TEXT} handleFilterChange={handleFilterChange} />
			<ResetFilters setActiveFilters={setActiveFilters} activeFiltersInitialState={activeFiltersInitialState} activeFilters={activeFilters} handleFilterChange={handleFilterChange} />

			{isFilterOpen ? (
				<div className="w-60 rounded-md shadow-primary p-4 bg-foreground absolute z-20 -left-4 top-14  ">
					<ProjectDetailsFilterPopup
						setFilterOpen={setFilterOpen}
						setLoadingBuildDetailsTable={setLoadingBuildDetailsTable}
						activeFilters={activeFilters}
						setActiveFilters={setActiveFilters}
						handleFilterChange={handleFilterChange}
						filters={filters}
					/>
				</div>
			) : (
				""
			)}
		</>
	);
};

export default ProjectFilter;
