const AXIOS_TIMEOUT_IN_SEC = 15;

const PAGINATED_RECORDS_PER_PAGE = 10;

const SESSION_STATUS = {
	INPROGRESS: "in-progress",
	COMPLETED: "completed",
	FAILED: "fail",
	PASSED: "pass"
};

const SESSION_STATUS_TEXT = {
	[SESSION_STATUS.INPROGRESS]: "In Progress",
	[SESSION_STATUS.COMPLETED]: "Completed",
	[SESSION_STATUS.FAILED]: "Failed",
	[SESSION_STATUS.PASSED]: "Passed"
};

const SESSION_STATUS_HEX = {
	[SESSION_STATUS.INPROGRESS]: "#E0A455",
	[SESSION_STATUS.COMPLETED]: "#1C7DF4",
	[SESSION_STATUS.FAILED]: "#E85252",
	[SESSION_STATUS.PASSED]: "#00BD85"
};

const SESSION_FILTER = {
	STATUS: "status",
	OS: "os",
	DEVICES: "devices",
	TAGS: "tags"
};

const SESSION_FILTERS_TEXT = {
	[SESSION_FILTER.STATUS]: "Status",
	[SESSION_FILTER.OS]: "OS",
	[SESSION_FILTER.DEVICES]: "Device",
	[SESSION_FILTER.TAGS]: "Tag"
};

const BUILD_FILTER = {
	STATUS: "status",
	BUILD_NAME: "buildname",
	PROJECT_NAME: "projectname"
};

const BUILD_FILTER_TEXT = {
	[BUILD_FILTER.STATUS]: "Status",
	[BUILD_FILTER.BUILD_NAME]: "Build Name",
	[BUILD_FILTER.PROJECT_NAME]: "Project Name"
};

const OS = {
	ANDROID: "android",
	IOS: "ios"
};

const OS_TEXT = {
	[OS.ANDROID]: "Android",
	[OS.IOS]: "iOS"
};

const BUILD_STATUS = {
	INPROGRESS: "in-progress",
	COMPLETED: "completed"
};

const BUILD_STATUS_TEXT = {
	[BUILD_STATUS.INPROGRESS]: "In Progress",
	[BUILD_STATUS.COMPLETED]: "Completed"
};

export {
	AXIOS_TIMEOUT_IN_SEC,
	SESSION_STATUS,
	SESSION_STATUS_TEXT,
	SESSION_STATUS_HEX,
	SESSION_FILTER,
	SESSION_FILTERS_TEXT,
	OS,
	OS_TEXT,
	BUILD_STATUS,
	BUILD_STATUS_TEXT,
	BUILD_FILTER,
	BUILD_FILTER_TEXT,
	PAGINATED_RECORDS_PER_PAGE
};
