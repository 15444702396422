import { SESSION_STATUS } from "../../../../constants";
import { SLDP } from "./sldp";
import { AMS } from "./ams";
import { OND } from "./ond";
import NoDataFound from "../../../../components/no-data-found";
import LoadingVideo from "./loading";

const SessionVideo = ({ session }) => {
	return (
		<>
			{session.status === SESSION_STATUS.INPROGRESS ? (
				session.os === "ios" ? (
					session.videoUrl.includes("wss://") === true ? (
						<SLDP url={session.videoUrl} />
					) : (
						<AMS url={session.videoUrl} />
					)
				) : (
					<NoDataFound msg="No live view available for android devices" />
				)
			) : session.videoUrl ? (
				session.videoUrl.includes("WebRTCAppEE") === true ? (
					<LoadingVideo msg="Waiting for video" />
				) : (
					<OND url={session.videoUrl} />
				)
			) : (
				<NoDataFound msg="No video found for session" />
			)}
		</>
	);
};

export default SessionVideo;
