import ModalImage from "react-modal-image";

const Screenshot = ({ url }) => {
	return (
		<>
			<div className="order-1 rounded-lg px-4 pb-6 pt-2 w-full">
				<ModalImage small={url} large={url} className="modal-image" alt="Screenshot" hideZoom={true} hideDownload={true} />
			</div>
		</>
	);
};

export default Screenshot;
